import styled from "styled-components";
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



const Container = styled("div")`
    justify-content: flex-start;
    position: relative;
    height: auto;
    background-color: #212925;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    @media (max-width: 700px) {
        display: none;

    }
`

const Title = styled("h1")`
    float:left;
    max-width: 100%;
    height: auto;
    color:rgba(255,255,255,.55);
`

const Link = styled("a")`
     
    font-family: 'Quantico', sans-serif;
    font-size:1rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    display: block;
    padding: 0.5rem 1rem;
    color:rgba(255,255,255,.55);
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;

    &:hover {
        color: #F28121
    }
    @media (max-width: 700px) {
        position: initial;

    }
`

const CrunchiLogo = styled("img")`

height: auto;
object-fit: cover;
object-position: top;
border-radius: 10%;
float:left;
padding: 5px;
width: 20%;
margin-left:3%;
`

const Brands = styled("img")`

width:auto;
`

const NavContainer = styled("div")`
    flex-direction: row;
    adding-right: 3rem!important;
    padding-left: 3rem!important;
    margin-right: auto!important;
    margin-left: auto!important;
    display: flex;
    margin-bottom: 0;
    list-style: none;

`

const Navbar = () => {
    return (
        <Container>
            <Title>
            <CrunchiLogo src="./LOGO_HORIZONTAL.png" alt="crunchimunchilogo"/>
            </Title>
            <NavContainer>
            <Link href="https://crunchimunchi.ca">
                Home
            </Link>
            <Link href="https://www.crunchimunchi.ca/#item-3">
                Origin Story
            </Link>
            <Link href="https://www.crunchimunchi.ca/#item-5">
                Creators
            </Link>
            <Link className="socialhide" href="https://mobile.twitter.com/crunchi_munchi">
         
           <i className="fa-brands fa-twitter"></i>
           </Link>
           <Link className="socialhide" href="https://discord.gg/7agXmgxE">
                <i className="fa-brands fa-discord"></i>
            </Link>
            <Link className="socialhide" href="https://www.instagram.com/crunchi.munchi/">
                <i className="fa-brands fa-instagram"></i>
            </Link>
            </NavContainer>
        </Container>
    )
}

export default Navbar;