import React from "react";
import styled from "styled-components";

const CrunchiLogo = styled("img")`

padding: 5px;
width: 150px;
`

const Footer = () => (
 
<div className="footer-clean mt-5">
<footer>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-sm-4 col-md-3 item">
                    <ul>
                        <li><a href="https://crunchimunchi.ca">HOME</a></li>
                        <li><a href="https://www.crunchimunchi.ca/terms.html">Terms & Conditions</a></li>
                       
                    </ul>
                </div>
                <div className="col-lg-3 item social">
                    <a href="https://mobile.twitter.com/crunchi_munchi" target="_blank"><i className="fa-brands fa-twitter"></i></a>
                    <a href="https://discord.gg/7agXmgxE" target="_blank"><i className="fa-brands fa-discord"></i></a>
                    <a href="https://www.instagram.com/crunchi.munchi/" target="_blank"><i className="fa-brands fa-instagram"></i></a>
                    <p className="copyright"><CrunchiLogo src="./LOGO_VERTICAL.png" alt="crunchimunchilogo"/> © 2022</p>
                </div>
            </div>
        </div>
    </footer>
</div>
);

export default Footer;